<template>
  <div class="maincomp">
    <el-row :gutter="20">

      <el-col :span="6">
        <el-card class="first-card" shadow="hover">
          <ul id="main_info" style="line-height: 35px">
            <li>用户：{{ info.name }} ({{ info.pg }})</li>
            <li>电话：{{ info.phone }}</li>
            <li>区域：{{ info.province }} - {{ info.city }}</li>
            <li>地址：{{ info.addr }}</li>
            <!-- <li>可分配次数：{{ info.atimes }}</li> -->
            <li>上次登陆时间：{{ info.login_time }}</li>
          </ul>
          <el-divider></el-divider>


          <el-form :inline="true" :model="search">
            <el-form-item>
              <el-input v-model="search.name" placeholder="设备名"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="onRefresh">查看</el-button>
            </el-form-item>
          </el-form>
          <el-checkbox v-model="search.ol" style="padding-right: 15px;padding-left: 4px">只看在线设备</el-checkbox>
          <!--          <el-divider  direction="vertical"></el-divider>-->
          <!--          <span style="padding-left: 15px">自动刷新 </span>-->
          <!--          <el-switch-->
          <!--              v-model="auto_reflesh"-->
          <!--              active-color="#13ce66"-->
          <!--              inactive-color="#ff4949">-->
          <!--          </el-switch>-->
        </el-card>
      </el-col>

      <!-- 循环卡片-->


      <el-col :span="6" v-for='(v, i) in cardlist' :key='i'>
        <el-card class="box-card" shadow="hover">
          <div slot="header" class="clearfix">
            <span>{{ v.name }}</span>
            <el-button @click="OpenModifyDialog(v.sn,v.name)" style="float: right; padding: 3px 0" type="text">设备命名
            </el-button>
          </div>
          <div id="main_info" style="display: flex; align-items: center;justify-content: space-around; line-height: 20px;padding-left: 20px;padding-right: 20px">
            <img :src="'/img/status/' + v.status + '.png'" alt="HZC" style="width: 100px; height: 100px; margin-right: 32px" />
            <div>
              <p style="font-weight: bold;">状态：{{ v.status }}</p>
              <p style="font-weight: bold;">编号：{{ v.sn }}</p>
<!--              <p>净化温度：{{ v.pt }}℃</p>-->
<!--              <p>仓内温度：{{ v.lt }}℃</p>-->
              <p>剩余次数：{{ v.times }}次</p>
            </div>
          </div>

<!--          <div style="border-top: 1px dashed #ccc;width: 100%;height: 0;padding-top: 5px;padding-bottom:6px"></div>-->

<!--          <div style="display: flex;justify-content:space-around">-->
<!--            <el-button @click="DeviceTrun(v.sn,'0')" type="danger" style="width: 90px">停止</el-button>-->
<!--            <el-button @click="DeviceTrun(v.sn,'1')" type="success" style="width: 90px">启动</el-button>-->
<!--          </div>-->
        </el-card>
      </el-col>
      

    </el-row>
<!-- 充值次数 Start -->
<el-dialog title="设备设置" :visible.sync="RechargeTimesDialog" width="400px">
        <el-form>
          <el-form-item label="充值次数：" label-width="100px">
            <el-input type="number" v-model="RechargeEditor" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <p style="margin-left: 16px"><i>当前剩余可分配次数:</i> <b style="margin-left: 4px">{{ info.atimes }}</b></p>
        <div slot="footer" class="dialog-footer">
          <el-button @click="RechargeTimesDialog = false">取 消</el-button>
          <el-button type="success" @click="RechargeCommit">充 次</el-button>
        </div>
      </el-dialog>
      <!-- 充值次数 END -->
      <!-- 修改床名字 Start -->
      <el-dialog title="修改名称" :visible.sync="modifyDeviceNameDialog" width="500px">
        <el-form>
          <el-form-item label="重命名：" label-width="80px">
            <el-input v-model="modifyEditor" autocomplete="off" maxlength="16" show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="modifyDeviceNameDialog = false">取 消</el-button>
          <el-button type="primary" @click="ModifyNameCommit">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 修改床名字 END -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        name: '',
        ol: false,
      },
      auto_reflesh: false,
      cardlist: null,
      total: 0,
      page: 0,
      count: 0,

      // 充次
      RechargeTimesDialog: false,
      RechargeEditor: "20",
      RechargeSN: "",
      // 修改名字
      modifyDeviceNameDialog: false,
      modifyEditor: "",
      modifySN: "",
    }
  },
  created() {
    this.info = this.$store.state.userInfo
    if (this.info.atimes < 0)
      this.info.atimes = " / "
    this.onRefresh();
  },
  mounted() {

  },
  beforeDestroy() {
    //this.closeTimer()
  },
  methods: {
    // 启动设备
    async DeviceTrun(sn,turn) {
      let res = await this.$api.turn_device({
        sn: sn,
        turn: turn,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
      }
    },
    // 充次弹窗
    OpenRechargeDialog(sn) {
      this.RechargeSN = sn
      this.RechargeTimesDialog = true
    },
    async RechargeCommit() {
      let res = await this.$api.charge_device({
        sn: this.RechargeSN,
        times: this.RechargeEditor.toString(),
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '充次已发送',
          type: 'success',
        })
        this.onRefresh();
        if (this.info.atimes != -1 && res.atimes != -2) {
          this.info.atimes = res.atimes
          this.$store.dispatch('Login', this.info)
        }
      }
      this.RechargeTimesDialog = false
    },
    // 修改名字
    OpenModifyDialog(sn, name) {
      this.modifyEditor = name
      this.modifySN = sn
      this.modifyDeviceNameDialog = true
    },
    async ModifyNameCommit() {
      let res = await this.$api.rename_device({
        sn: this.modifySN,
        rename: this.modifyEditor,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '设备名称修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.modifyDeviceNameDialog = false
    },


    async onRefresh() {
      console.log(this.search.ol)
      let res = await this.$api.card({
        name: this.search.name,
        ol: this.search.ol,
      })
      console.log(res)
      if (res.status == 1) {
        this.cardlist = res.data
        this.total = res.total
        this.page = res.page
        this.count = res.count

      }
    },


  },
}
</script>

<style scoped>
.first-card {
  height: 660px;
}

.box-card {
  margin-bottom: 10px;
}
</style>
